<template>
  <div
    :id="'widget_' + index"
    :key="componentKey"
  >

    <!-- 1: Text -->

    <table
      v-if="(widget.widgettypeid==1) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <p>
            {{widget.instance.text}}
          </p>         
        </td>        
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==1) && !moderator"
    >
      {{widget.instance.text}}
    </p>

    <!-- 2: Numbered List -->

    <table
      v-if="(widget.widgettypeid==2) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <p>
            <ol>
                <li
                  v-for="(item, index) in widget.instance.items"
                  :key="index"
                >
                  {{item.text}}
                </li>
            </ol>
          </p>        
        </td>        
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==2) && !moderator"
    >
      <ol>
          <li
            v-for="(item, index) in widget.instance.items"
            :key="index"
          >
            {{item.text}}
          </li>
      </ol>
    </p>

    <!-- 3: Bullet List -->

    <table
      v-if="(widget.widgettypeid==3) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <p>
            <ul>
                <li
                  v-for="(item, index) in widget.instance.items"
                  :key="index"
                >
                  {{item.text}}
                </li>
            </ul>
          </p>        
        </td>        
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==3) && !moderator"
    >
      <ul>
          <li
            v-for="(item, index) in widget.instance.items"
            :key="index"
          >
            {{item.text}}
          </li>
      </ul>
    </p>

    <!-- 4: Image -->

    <table
      v-if="(widget.widgettypeid==4) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <p>
            <img
              style="max-width:100%; max-height:100%;"
              :src="'https://api.metricforms.com/resources/' + widget.instance.imagefile"
            >
          </p>    
        </td>        
      </tr>
    </table>

    <p
      v-if="(widget.widgettypeid==4) && !moderator"
    >
      <img
        style="max-width:100%; max-height:100%;"
        :src="'https://api.metricforms.com/resources/' + widget.instance.imagefile"
      >
    </p>

    <!-- 5: Video -->

    <table
      v-if="(widget.widgettypeid==5) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <iframe 
            class="embed-responsive-item" 
            width="640"
            height="360"
            style="max-width:100%; max-height:100%;"
            :src="'https://www.youtube.com/embed/' + widget.instance.videoid + '?rel=0'" 
            allowfullscreen
          >
          </iframe>
        </td>        
      </tr>
    </table>

    <iframe 
      v-if="(widget.widgettypeid==5) && !moderator"
      class="embed-responsive-item" 
      width="640"
      height="360"      
      style="max-width:100%; max-height:100%;"
      :src="'https://www.youtube.com/embed/' + widget.instance.videoid + '?rel=0'" 
      allowfullscreen
    >
    </iframe>

    <!-- 6: Checkbox Group -->

    <table
      v-if="(widget.widgettypeid==6) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <v-checkbox
            v-for="(item, index) in widget.instance.items"
            :key="index"
            :label="item.text"
            :value="index"
            hide-details
          ></v-checkbox>
        </td>        
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==6) && !moderator"
    >
      <v-checkbox
        v-for="(item, index) in widget.instance.items"
        :key="index"
        :label="item.text"
        :value="index"
        hide-details
      ></v-checkbox>
    </p>    

    <!-- 7: Radio Button Group -->

    <table
      v-if="(widget.widgettypeid==7) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <v-radio-group>
            <v-radio
              v-for="(item, index) in widget.instance.items"
              :key="index"
              :label="item.text"
              :value="index"
            ></v-radio>
          </v-radio-group>
        </td>        
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==7) && !moderator"
    >
      <v-radio-group
        v-model="widget.answer"
      >
        <v-radio
          v-for="(item, index) in widget.instance.items"
          :key="index"
          :label="item.text"
          :value="item.index"
          :disabled="showAnswers"
          hide-details
          @change="userAction(index)"
          style="color:#44aa44;"
        >
          <span 
            v-if="showAnswers && (item.index == 0)"
            slot="label" 
            style="color: #44aa44;"
            >{{ item.text }}</span>
        </v-radio>
        <span v-if="showAnswers && (widget.answer === 0)" style="color: #44aa44;">Correct</span>
        <span v-if="showAnswers && (widget.answer !== 0)" style="color: #cc4444;">Incorrect</span>
      </v-radio-group>
    </p>  

    <!-- 8: Textbox -->

    <table
      v-if="(widget.widgettypeid==8) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <v-text-field  
            :label="widget.instance.label"          
          ></v-text-field> 
        </td>        
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==8) && !moderator"
    >
      <v-text-field    
        :label="widget.instance.label"          
      ></v-text-field>    
    </p>  

    <!-- 9: PDF -->

    <table
      v-if="(widget.widgettypeid==9) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <p>
            <v-btn
              class="no-uppercase"
              @click="openPDF(widget.instance.pdffile)"        
            >
              <v-icon>mdi-file-pdf-box</v-icon><span>&nbsp;{{widget.instance.text}}</span>
            </v-btn>
          </p>  
        </td>        
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==9) && !moderator"
    >
      <v-btn
        class="no-uppercase"
        @click="openPDF(widget.instance.pdffile)"        
      >
        <v-icon>mdi-file-pdf-box</v-icon><span>&nbsp;{{widget.instance.text}}</span>
      </v-btn>
    </p>

    <!-- 10: Header -->

    <table
      v-if="(widget.widgettypeid==10) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <h1
            v-if="widget.widgettypeid==10"
          >
            {{widget.instance.text}}
          </h1>
        </td>
      </tr>
    </table>

    <h1
      v-if="(widget.widgettypeid==10) && !moderator"
    >
      {{widget.instance.text}}
    </h1> 

    <!-- 11: Exercise -->

    <table
      v-if="(widget.widgettypeid==11) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <p>
            <v-btn
              class="no-uppercase"
              @click="openExercise"        
            >
              <v-icon>mdi-application-outline</v-icon><span>&nbsp;&nbsp;{{widget.instance.text}} [{{widget.instance.exerciseid}}]</span>
            </v-btn>
          </p>
        </td>
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==11) && !moderator"
    >
      <v-btn
        class="no-uppercase"
        @click="openExercise"        
      >
        <v-icon>mdi-application-outline</v-icon><span>&nbsp;&nbsp;{{widget.instance.text}}</span>
      </v-btn>
    </p>

    <!-- 12: Link -->

    <table
      v-if="(widget.widgettypeid==12) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <p>
            <a
              :href="widget.instance.link"
            >
            {{widget.instance.link}}
            </a>
          </p>
        </td>
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==12) && !moderator"
    >
      <a
        :href="widget.instance.link"
      >
      {{widget.instance.link}}
      </a>
    </p>

    <!-- 13: Code Block -->

    <table
      v-if="(widget.widgettypeid==13) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <p>
            <pre
              class="code"
            >{{ widget.instance.code }}</pre>
          </p>
        </td>
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==13) && !moderator"
    >
      <pre
        class="code"
      >{{ widget.instance.code }}</pre>
    </p>

    <!-- 14: Code Solution -->

    <table
      v-if="(widget.widgettypeid==14) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <p>
            <v-btn
              class="no-uppercase"
              v-if="!widget.instance.visible"
              @click="showSolution"        
            >
              Show Solution
            </v-btn>

            <pre
              v-if="widget.instance.visible"
              class="code"
            >{{ widget.instance.code }}</pre>

          </p>
        </td>
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==14) && !moderator"
    >
      <v-btn
        class="no-uppercase"
        v-if="!widget.instance.visible"
        @click="showSolution"        
      >
        Show Solution
      </v-btn>

      <pre
        v-if="widget.instance.visible"
        class="code"
      >{{ widget.instance.code }}</pre>

    </p>

    <!-- 15: Flipbook -->

    <table
      v-if="(widget.widgettypeid==15) && moderator"
    >
      <tr
        valign="top"
      >
        <td>
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="editWidget" style="cursor: pointer;"><v-list-item-title>Edit</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetUp" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetDown" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
              <v-list-item @click="moveWidgetToTab" style="cursor: pointer;"><v-list-item-title>Move To Tab</v-list-item-title></v-list-item>
              <v-list-item @click="deleteWidget" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </td>
        <td>
          <p>
            <v-card
              v-if="widget.instance.pages.length > 0"
            >
              <v-card-title class="text-h5 grey lighten-2">
                {{widget.instance.text}}
              </v-card-title>

              <v-card-text>
                  <flipbook 
                    class="flipbook" 
                    :pages="flipbookPageLinks" 
                    :zooms="null"
                  >
                  </flipbook>
              </v-card-text>

            </v-card>  
            <span
              v-if="widget.instance.pages.length <= 0"
            >
              {{widget.instance.text}}: PDF missing
            </span>
          </p>
        </td>
      </tr>
    </table>

    <p      
      v-if="(widget.widgettypeid==15) && !moderator"
    >
        <v-card
            v-if="widget.instance.pages.length > 0"
        >
            <v-card-title class="text-h5 grey lighten-2">
            {{widget.instance.text}}
            </v-card-title>

            <v-card-text>
                <flipbook 
                class="flipbook" 
                :pages="flipbookPageLinks" 
                :zooms="null"
                >
                </flipbook>
            </v-card-text>

        </v-card>     
        <span
            v-if="widget.instance.pages.length <= 0"
        >
        {{widget.instance.text}}: PDF missing
        </span>      
    </p>

    <!-- Dialog: [1] Text -->

    <v-dialog
      v-if="(widget.widgettypeid==1) && moderator"
      v-model="dialogText"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Text
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-text-field
              v-model="dialogText_text"
              label="Text"
            ></v-text-field>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogText = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [2] Numbered List -->

    <v-dialog
      v-if="(widget.widgettypeid==2) && moderator"
      v-model="dialogNumberedList"
      width="500"      
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Numbered List
        </v-card-title>

        <v-card-text
          
        >

          <v-form
            ref="form"
          >
            <v-container>
              <v-row
                v-for="(item, index) in dialogNumberedList_items"
                :key="index"              
              >
                <v-text-field
                  v-model="item.text"
                >
                  {{item.text}} 
                </v-text-field>

                <v-icon
                  class="click-fix"
                  style="cursor: pointer;"
                  @click="actionNumberedList_moveUp(index)"
                >mdi-arrow-up-bold-circle-outline</v-icon>

                <v-icon    
                  class="click-fix"         
                  style="cursor: pointer;"
                  @click="actionNumberedList_moveUp(index + 1)"
                >mdi-arrow-down-bold-circle-outline</v-icon>   

                <v-icon
                  class="click-fix"
                  style="cursor: pointer;"
                  @click="actionNumberedList_remove(index)"                     
                >mdi-delete-circle-outline</v-icon>                              
              </v-row>

              <v-row>
                <v-icon   
                  class="click-fix"         
                  style="cursor: pointer;"
                  @click="actionNumberedList_add"
                >mdi-plus-circle-outline</v-icon>                
              </v-row>
            </v-container>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogNumberedList = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [3] Bullet List -->

    <v-dialog
      v-if="(widget.widgettypeid==3) && moderator"
      v-model="dialogBulletList"
      width="500"      
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Bullet List
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-container>
              <v-row
                v-for="(item, index) in dialogBulletList_items"
                :key="index"              
              >
                <v-text-field
                  v-model="item.text"
                >
                  {{item.text}} 
                </v-text-field>

                <v-icon
                  class="click-fix"
                  style="cursor: pointer;"
                  @click="actionBulletList_moveUp(index)"
                >mdi-arrow-up-bold-circle-outline</v-icon>

                <v-icon    
                  class="click-fix"         
                  style="cursor: pointer;"
                  @click="actionBulletList_moveUp(index + 1)"
                >mdi-arrow-down-bold-circle-outline</v-icon>   

                <v-icon
                  class="click-fix"
                  style="cursor: pointer;"
                  @click="actionBulletList_remove(index)"                     
                >mdi-delete-circle-outline</v-icon>                              
              </v-row>

              <v-row>
                <v-icon   
                  class="click-fix"         
                  style="cursor: pointer;"
                  @click="actionBulletList_add"
                >mdi-plus-circle-outline</v-icon>                
              </v-row>
            </v-container>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogBulletList = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [4] Image -->

    <v-dialog
      v-if="(widget.widgettypeid==4) && moderator"
      v-model="dialogImage"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Image
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-text-field
              v-model="dialogImage_imageResourceId"
              label="Image Resource Id"
            ></v-text-field>

            <v-btn 
              class="no-uppercase"
              :loading="dialogImage_isSelecting" 
              @click="dialogImage_handleFileImport"
            >
              Upload Image
            </v-btn>

            <input 
              ref="dialogImage_uploader" 
              class="d-none" 
              type="file" 
              @change="dialogImage_onFileChanged"
            >

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogImage = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [5] Video -->

    <v-dialog
      v-if="(widget.widgettypeid==5) && moderator"
      v-model="dialogVideo"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Video
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-text-field
              v-model="dialogVideo_videoId"
              label="YouTube Video Id"
            ></v-text-field>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogVideo = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [6] Checkbox Group -->

    <v-dialog
      v-if="(widget.widgettypeid==6) && moderator"
      v-model="dialogCheckboxGroup"
      width="500"      
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Checkbox Group
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-container>
              <v-row
                v-for="(item, index) in dialogCheckboxGroup_items"
                :key="index"              
              >
                <v-text-field
                  v-model="item.text"
                >
                  {{item.text}} 
                </v-text-field>

                <v-icon
                  class="click-fix"
                  style="cursor: pointer;"
                  @click="actionCheckboxGroup_moveUp(index)"
                >mdi-arrow-up-bold-circle-outline</v-icon>

                <v-icon    
                  class="click-fix"         
                  style="cursor: pointer;"
                  @click="actionCheckboxGroup_moveUp(index + 1)"
                >mdi-arrow-down-bold-circle-outline</v-icon>   

                <v-icon
                  class="click-fix"
                  style="cursor: pointer;"
                  @click="actionCheckboxGroup_remove(index)"                     
                >mdi-delete-circle-outline</v-icon>                              
              </v-row>

              <v-row>
                <v-icon   
                  class="click-fix"         
                  style="cursor: pointer;"
                  @click="actionCheckboxGroup_add"
                >mdi-plus-circle-outline</v-icon>                
              </v-row>
            </v-container>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogCheckboxGroup = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [7] Radio Button Group -->

    <v-dialog
      v-if="(widget.widgettypeid==7) && moderator"
      v-model="dialogRadioButtonGroup"
      width="500"      
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Radio Button Group
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-container>
              <v-row
                v-for="(item, index) in dialogRadioButtonGroup_items"
                :key="index"              
              >
                <v-text-field
                  v-model="item.text"
                >
                  {{item.text}} 
                </v-text-field>

                <v-icon
                  class="click-fix"
                  style="cursor: pointer;"
                  @click="actionRadioButtonGroup_moveUp(index)"
                >mdi-arrow-up-bold-circle-outline</v-icon>

                <v-icon    
                  class="click-fix"         
                  style="cursor: pointer;"
                  @click="actionRadioButtonGroup_moveUp(index + 1)"
                >mdi-arrow-down-bold-circle-outline</v-icon>   

                <v-icon
                  class="click-fix"
                  style="cursor: pointer;"
                  @click="actionRadioButtonGroup_remove(index)"                     
                >mdi-delete-circle-outline</v-icon>                              
              </v-row>

              <v-row>
                <v-icon   
                  class="click-fix"         
                  style="cursor: pointer;"
                  @click="actionRadioButtonGroup_add"
                >mdi-plus-circle-outline</v-icon>                
              </v-row>
            </v-container>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogRadioButtonGroup = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [8] Textbox -->

    <v-dialog
      v-if="(widget.widgettypeid==8) && moderator"
      v-model="dialogTextbox"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Textbox
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-text-field
              v-model="dialogTextbox_label"
              label="Label"
            ></v-text-field>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogTextbox = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [9] PDF -->

    <v-dialog
      v-if="(widget.widgettypeid==9) && moderator"
      v-model="dialogPDF"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          PDF
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >

            <v-text-field
              v-model="dialogPDF_text"
              label="Text"
            ></v-text-field>

            <v-text-field
              v-model="dialogPDF_pdfResourceId"
              label="PDF Resource Id"
            ></v-text-field>

            <v-btn 
              class="no-uppercase"
              :loading="dialogPDF_isSelecting" 
              @click="dialogPDF_handleFileImport"
            >
              Upload PDF
            </v-btn>

            <input 
              ref="dialogPDF_uploader" 
              class="d-none" 
              type="file" 
              @change="dialogPDF_onFileChanged"
            >

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogPDF = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [10] Edit Header -->

    <v-dialog
      v-if="(widget.widgettypeid==10) && moderator"
      v-model="dialogHeader"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Header
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-text-field
              v-model="dialogHeader_text"
              label="Text"
            ></v-text-field>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogHeader = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [11] Exercise -->

    <v-dialog
      v-if="widget.widgettypeid==11"
      v-model="dialogExercise"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Exercise
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-text-field
              v-model="dialogExercise_text"
              label="Text"
            ></v-text-field>
            
            <v-text-field
              v-model="dialogExercise_exerciseId"
              label="Exercise ID"
            ></v-text-field>

            <v-text-field
              v-model="dialogExercise_questions"
              label="Questions"
            ></v-text-field>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogExercise = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [12] Edit Link -->

    <v-dialog
      v-if="(widget.widgettypeid==12) && moderator"
      v-model="dialogLink"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Link
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-text-field
              v-model="dialogLink_link"
              label="Text"
            ></v-text-field>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogLink = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [13] Code Block -->

    <v-dialog
      v-if="(widget.widgettypeid==13) && moderator"
      v-model="dialogCodeBlock"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Code Block
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >

            <v-textarea
              v-model="dialogCodeBlock_code"
              label="Code"
            ></v-textarea>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogCodeBlock = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [14] Code Solution -->

    <v-dialog
      v-if="(widget.widgettypeid==14) && moderator"
      v-model="dialogCodeSolution"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Code Solution
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >

            <v-textarea
              v-model="dialogCodeSolution_code"
              label="Code"
            ></v-textarea>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogCodeSolution = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: [15] Flipbook -->

    <v-dialog
      v-if="(widget.widgettypeid==15) && moderator"
      v-model="dialogFlipbook"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Flipbook
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >

            <v-text-field
              v-model="dialogFlipbook_text"
              label="Text"
            ></v-text-field>

            <v-btn 
              class="no-uppercase"
              :loading="dialogFlipbook_isSelecting" 
              @click="dialogFlipbook_handleFileImport"
            >
              Upload PDF
            </v-btn>

            <input 
              ref="dialogFlipbook_uploader" 
              class="d-none no-uppercase" 
              type="file" 
              @change="dialogFlipbook_onFileChanged"
            >

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="dialogFlipbook = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            class="no-uppercase"
            color="primary"
            text
            @click="updateWidget"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
  import errorUtils from '@/utils/errorUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  const CourseRepository = RepositoryFactory.get('course');
  const ResourceRepository = RepositoryFactory.get('resource');

  export default {
    name: 'Widget',

    components: {
      Flipbook: () => import("flipbook-vue"),
    },

    data: () => ({
      componentKey: 0,
      
      // [1] Text
      dialogText: false,
      dialogText_text: '',

      // [2] Numbered List
      dialogNumberedList: false,
      dialogNumberedList_items: [],      

      // [3] Bullet List
      dialogBulletList: false,
      dialogBulletList_items: [], 

      // [4] Image
      dialogImage: false,
      dialogImage_imageResourceId: 0, 
      dialogImage_isSelecting: false,

      // [5] Video
      dialogVideo: false,
      dialogVideo_videoId: 0, 

      // [6] Checkbox Group
      dialogCheckboxGroup: false,
      dialogCheckboxGroup_items: [], 

      // [7] Radio Button Group
      dialogRadioButtonGroup: false,
      dialogRadioButtonGroup_items: [], 

      // [8] Textbox
      dialogTextbox: false,
      dialogTextbox_label: '',

      // [9] PDF
      dialogPDF: false,
      dialogPDF_text: '',
      dialogPDF_pdfResourceId: 0, 
      dialogPDF_isSelecting: false,

      // [10] Header
      dialogHeader: false,
      dialogHeader_text: '',

      // [11] Exercise
      dialogExercise: false,
      dialogExercise_text: '',
      dialogExercise_exerciseId: '',
      dialogExercise_questions: 0,

      // [12] Link
      dialogLink: false,
      dialogLink_link: '',

      // [13] Code Block
      dialogCodeBlock: false,
      dialogCodeBlock_code: '',

      // [14] Code Solution
      dialogCodeSolution: false,
      dialogCodeSolution_code: '',

      // [15] Flipbook
      dialogFlipbook: false,
      dialogFlipbook_text: '',
      dialogFlipbook_isSelecting: false,

    }),

    props: {    

      moderator: {
        type: Boolean,
        default: false,
        required: false
      },

      index: {
        type: Number,
        required: true        
      },

      widget: {
        type: Object,
        required: true
      },

      showAnswers: {
        type: Boolean,
        default: false,
        required: false
      },

    },

    computed: {
      flipbookPageLinks: function() {
        let links = [];
        let i = 0;
        while (i < this.widget.instance.pages.length) {
          links.push('https://api.metricforms.com/resources/' + this.widget.instance.pages[i]);
          i++;
        }
        return links;
      }
    },

    methods: {

      actionBulletList_add() {
        this.dialogBulletList_items.push({
          text: "Bullet item " + (this.dialogBulletList_items.length + 1)
        })
      },

      actionBulletList_moveUp(index) {
        var tmpItem;        
        if ((index > 0) && (index < this.dialogBulletList_items.length)) {
          tmpItem = this.dialogBulletList_items[index - 1];
          this.dialogBulletList_items[index - 1] = this.dialogBulletList_items[index];
          this.dialogBulletList_items[index] = tmpItem;
          this.$forceUpdate();
        }
      },

      actionBulletList_remove(index) {
        this.dialogBulletList_items.splice(index, 1);
      },

      actionCheckboxGroup_add() {
        this.dialogCheckboxGroup_items.push({
          text: "Check item " + (this.dialogCheckboxGroup_items.length + 1)
        })
      },

      actionCheckboxGroup_moveUp(index) {
        var tmpItem;        
        if ((index > 0) && (index < this.dialogCheckboxGroup_items.length)) {
          tmpItem = this.dialogCheckboxGroup_items[index - 1];
          this.dialogCheckboxGroup_items[index - 1] = this.dialogCheckboxGroup_items[index];
          this.dialogCheckboxGroup_items[index] = tmpItem;
          this.$forceUpdate();
        }
      },

      actionCheckboxGroup_remove(index) {
        this.dialogCheckboxGroup_items.splice(index, 1);
      },

      actionNumberedList_add() {
        this.dialogNumberedList_items.push({
          text: "Numbered item " + (this.dialogNumberedList_items.length + 1)
        })
      },

      actionNumberedList_moveUp(index) {
        var tmpItem;        
        if ((index > 0) && (index < this.dialogNumberedList_items.length)) {
          tmpItem = this.dialogNumberedList_items[index - 1];
          this.dialogNumberedList_items[index - 1] = this.dialogNumberedList_items[index];
          this.dialogNumberedList_items[index] = tmpItem;
          this.$forceUpdate();
        }
      },

      actionNumberedList_remove(index) {
        this.dialogNumberedList_items.splice(index, 1);
      },

      actionRadioButtonGroup_add() {
        this.dialogRadioButtonGroup_items.push({
          text: "Check item " + (this.dialogRadioButtonGroup_items.length + 1)
        })
      },

      actionRadioButtonGroup_moveUp(index) {
        var tmpItem;        
        if ((index > 0) && (index < this.dialogRadioButtonGroup_items.length)) {
          tmpItem = this.dialogRadioButtonGroup_items[index - 1];
          this.dialogRadioButtonGroup_items[index - 1] = this.dialogRadioButtonGroup_items[index];
          this.dialogRadioButtonGroup_items[index] = tmpItem;
          this.$forceUpdate();
        }
      },

      actionRadioButtonGroup_remove(index) {
        this.dialogRadioButtonGroup_items.splice(index, 1);
      },

      deleteWidget() {
        this.$emit('deleteWidget', this.index);
      },

      dialogImage_handleFileImport() {
          this.dialogImage_isSelecting = true;

          // After obtaining the focus when closing the FilePicker, return the button state to normal
          window.addEventListener('focus', () => {
              this.dialogImage_isSelecting = false
          }, { once: true });
          
          // Trigger click on the FileInput
          this.$refs.dialogImage_uploader.click();
      },

      async dialogImage_onFileChanged(e) {        
        const { data } = await ResourceRepository.uploadImage(e.target.files[0]);
        if (data.success) {
          this.dialogImage_imageResourceId = data.data.imageresourceid;
          this.updateWidget();
        } else {          
          errorUtils.showError(data.message);
        } 
      },

      dialogPDF_handleFileImport() {
          this.dialogPDF_isSelecting = true;

          // After obtaining the focus when closing the FilePicker, return the button state to normal
          window.addEventListener('focus', () => {
              this.dialogPDF_isSelecting = false
          }, { once: true });
          
          // Trigger click on the FileInput
          this.$refs.dialogPDF_uploader.click();
      },

      async dialogPDF_onFileChanged(e) {        
        const { data } = await ResourceRepository.uploadPDF(e.target.files[0]);
        if (data.success) {
          this.dialogPDF_pdfResourceId = data.data.pdfresourceid;
          this.updateWidget();
        } else {          
          errorUtils.showError(data.message);
        } 
      },

      dialogFlipbook_handleFileImport() {
          this.dialogFlipbook_isSelecting = true;

          // After obtaining the focus when closing the FilePicker, return the button state to normal
          window.addEventListener('focus', () => {
              this.dialogFlipbook_isSelecting = false
          }, { once: true });
          
          // Trigger click on the FileInput
          this.$refs.dialogFlipbook_uploader.click();
      },

      async dialogFlipbook_onFileChanged(e) { // eslint-disable-line   
        const { data } = await ResourceRepository.uploadFlipbookPDF(this.widget.lessonwidgetid, e.target.files[0]);
        if (data.success) {
          this.widget.instance.pages = data.data.pages;
          this.dialogFlipbook = false;
        } else {          
          errorUtils.showError(data.message);
        }
      },

      editWidget() {
        //alert(JSON.stringify(this.widget));

        switch (this.widget.widgettypeid) {

          case 1: // text
            this.dialogText_text = this.widget.instance.text.trim();
            this.dialogText = true;            
            break;

          case 2: // numbered list          
            this.dialogNumberedList_items = JSON.parse(JSON.stringify(this.widget.instance.items));
            this.dialogNumberedList = true;            
            break;

          case 3: // bullet list        
            this.dialogBulletList_items = JSON.parse(JSON.stringify(this.widget.instance.items));
            this.dialogBulletList = true;      
            break;

          case 4: // image            
            this.dialogImage_imageResourceId = this.widget.instance.imageresourceid;
            this.dialogImage = true;      
            break;
          
          case 5: // video            
            //alert(JSON.stringify(this.widget.instance));
            this.dialogVideo_videoId = this.widget.instance.videoid;
            this.dialogVideo = true;      
            break;

          case 6: // checkbox group
            this.dialogCheckboxGroup_items = JSON.parse(JSON.stringify(this.widget.instance.items));
            this.dialogCheckboxGroup = true;      
            break;

          case 7: // radio button group
            this.dialogRadioButtonGroup_items = JSON.parse(JSON.stringify(this.widget.instance.items));
            this.dialogRadioButtonGroup = true;      
            break;

          case 8: // textbox
            this.dialogTextbox_label = this.widget.instance.label.trim();
            this.dialogTextbox = true;      
            break;

          case 9: // PDF
            this.dialogPDF_text = this.widget.instance.text.trim();
            this.dialogPDF_pdfResourceId = this.widget.instance.pdfresourceid;
            this.dialogPDF = true;      
            break;

          case 10: // header
            this.dialogHeader_text = this.widget.instance.text.trim();
            this.dialogHeader = true;            
            break;

          case 11: // exercise
            this.dialogExercise_text = this.widget.instance.text.trim();
            this.dialogExercise_exerciseId = this.widget.instance.exerciseid;
            this.dialogExercise_questions = 0;
            if (this.widget.instance.questions) {
              this.dialogExercise_questions = this.widget.instance.questions;
            }
            this.dialogExercise = true;            
            break;

          case 12: // link
            this.dialogLink_link = this.widget.instance.link.trim();
            this.dialogLink = true;            
            break;

          case 13: // code block
            this.dialogCodeBlock_code = this.widget.instance.code;
            this.dialogCodeBlock = true;            
            break;

          case 14: // code solution
            this.dialogCodeSolution_code = this.widget.instance.code;
            this.dialogCodeSolution = true;            
            break;

          case 15: // Flipbook
            this.dialogFlipbook_text = this.widget.instance.text.trim();
            this.dialogFlipbook = true;      
            break;

          default:
            alert('todo: edit widget');
        }
        
      },

      moveWidgetDown() {
        this.$emit('moveWidgetDown', this.index);
      },

      moveWidgetUp() {
        this.$emit('moveWidgetUp', this.index);
      },

      moveWidgetToTab() {
        this.$emit('moveWidgetToTab', this.index);
      },

      openExercise() {         
        this.$emit('openExercise', this.widget);
      },

      openPDF(resource) {         
        window.open('https://api.metricforms.com/resources/' + resource, '_blank') //to open in new tab
      },

      showSolution() {        
        this.widget.instance.visible = true;
        this.componentKey += 1;
      },

      userAction(index) {
        this.$emit('userAction', this.widget, index);
      },

      async updateWidget() {

        switch (this.widget.widgettypeid) {

          case 1: // text
            this.widget.instance.text = this.dialogText_text.trim();
            this.dialogText = false;
            break;

          case 2: // numbered list
            this.widget.instance.items = this.dialogNumberedList_items;            
            this.dialogNumberedList = false;
            break;

          case 3: // bullet list
            this.widget.instance.items = this.dialogBulletList_items;            
            this.dialogBulletList = false;
            break;

          case 4: // image
            this.widget.instance.imageresourceid = this.dialogImage_imageResourceId;
            this.dialogImage = false;
            break;

          case 5: // video
            this.widget.instance.videoid = this.dialogVideo_videoId;
            this.dialogVideo = false;
            break;

          case 6: // checkbox group
            this.widget.instance.items = this.dialogCheckboxGroup_items;            
            this.dialogCheckboxGroup = false;
            break;

          case 7: // radio button group
            this.widget.instance.items = this.dialogRadioButtonGroup_items;            
            this.dialogRadioButtonGroup = false;
            break;

          case 8: // textbox
            this.widget.instance.label = this.dialogTextbox_label.trim();            
            this.dialogTextbox = false;
            break;

          case 9: // pdf
            this.widget.instance.text = this.dialogPDF_text.trim();
            this.widget.instance.pdfresourceid = this.dialogPDF_pdfResourceId;
            this.dialogPDF = false;
            break;

          case 10: // header
            this.widget.instance.text = this.dialogHeader_text.trim();
            this.dialogHeader = false;
            break;       

          case 11: // exercise
            this.widget.instance.text = this.dialogExercise_text.trim();
            this.widget.instance.exerciseid = parseInt(this.dialogExercise_exerciseId);
            this.widget.instance.questions = parseInt(this.dialogExercise_questions);
            this.dialogExercise = false;            
            break;

          case 12: // link
            this.widget.instance.link = this.dialogLink_link.trim();
            this.dialogLink = false;
            break;     

          case 13: // code block
            this.widget.instance.code = this.dialogCodeBlock_code.trim();
            this.dialogCodeBlock = false;
            break;   

          case 14: // code solution
            this.widget.instance.code = this.dialogCodeSolution_code.trim();
            this.dialogCodeSolution = false;
            break;  

          case 15: // flipbook
            this.widget.instance.text = this.dialogFlipbook_text.trim();
            this.dialogFlipbook = false;
            break;

          default:
            alert('todo: update widget');
        }

        const { data } = await CourseRepository.updateWidget(this.widget.lessonwidgetid, JSON.stringify(this.widget.instance));
        if (data.success) {
          this.widget.instance = data.data.instance;
        } else {          
          errorUtils.showError(data.message);
        }        

      }

    },

  }
</script>

<style scoped>

  .code {
    background: #cccccc;
  }

  .click-fix:focus::after { opacity: 0 !important; }

  .flipbook {
    width: 70vw;
    height: 70vh;
  }

  .no-uppercase {
    text-transform: unset !important;
  }

</style>